import "aos/dist/aos.css";
import Aos from "aos";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { antdTheme, theme } from "./components/styled/Theme";
import { MainContainer } from "./components/MainContainer";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ConfigProvider } from "antd";

function App() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={antdTheme}>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <BrowserRouter>
          <MainContainer />
        </BrowserRouter>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
