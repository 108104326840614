import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { tradeFinanceData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";
import { contactLink } from "../constants/links";

export const HeaderSection = () => (
  <Wrapper className="gradient1 combDark">
    <div className="container whiteColor">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              <HighlightSpan>Registration</HighlightSpan> Information
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
              <b>Register</b> Today
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
          Welcome to the registration page for the Pan African Universities Debating Championship 
          (PAUDC) 2024! Here's everything you need to know to secure your spot at this prestigious event:
          </TitleP>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <HeaderButton to={contactLink}>Register Today</HeaderButton>
        </div>
      </LeftSide>
    </div>
  </Wrapper>
  
);

export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
          <b>Registration Process: </b> To register for PAUDC 2024, please fill out the online registration form available on our website. Make sure to provide accurate information to expedite the process.
          <br />
          <br />
          <b>Participant Categories: </b> We offer registration for debaters, institutional judges, observers, and officials. Choose the category that best fits your role in the event.
          <br />
          <br />
          <b>Package Selection:</b> Select the package that aligns with your preferences and requirements. We offer different packages tailored to accommodate various needs and budgets.
          <br />
          <br />
          <b>Payment Options: </b> Payment for registration can be made securely online through our payment gateway. We also offer installment payment plans for certain packages, allowing you to spread the cost leading up to the event.
          <br />
          <br />
          <b>Confirmation: </b> Once your registration and payment are processed successfully, you will receive a confirmation email containing important details about the event, including your registration ID and further instructions.
          <br />
          <br />
          <b>Deadline:</b> Be sure to complete your registration before the deadline to secure your spot at PAUDC 2024. Late registrations may not be accepted, so don't miss out on this incredible opportunity!
          <br />
          <br />
          </TitleP>
        </div>
    </div>
  </ContentWrapper>
);

export const Registration = () => {
  return (
    <>
      <HeaderSection />
      <ContentSection />
      <PatternStrip />
      <Footer />
    </>
  );
};

const Wrapper = styled.section`
  padding-top: 90px;
  padding-bottom: 50px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
  background: url('./banner14.jpg');
  background-size: cover; /* Ensure the background image covers the whole viewport */
  background-position: center; /* Center the background image */
  color: white; /* Set text color to white */
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
