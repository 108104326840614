import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../constants/routes";
import TopNavbar from "./nav-bar/TopNavbar";
import { ScrollTop } from "./elements/ScrollTop";
import { paudcBot } from "./chatbot/paudcBot";

export const MainContainer = () => {
  return (
    <>
      <TopNavbar />
      <paudcBot />
      <ScrollTop />
      <Routes>
        {routes.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
};
