import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as LinkDOM, useNavigate } from "react-router-dom";

// Assets
import Sidebar from "../sidebar/Sidebar";
import { MenuRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { contactLink, navLinks } from "../../constants/links";
import Backdrop from "../elements/Backdrop";
import { getLocation } from "../../utils/Utils";
import { Dropdown } from "../dropdown/Dropdown";
import paudclogo1 from "../../assets/svg/logo/paudclogo1.png";
export const navStyle = { padding: "10px 15px" };

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const handleClick = () => {
    if (getLocation() !== "/") {
      navigate("/");
    }
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate"
        style={
          y > 100
            ? {
                height: "60px",
              }
            : { height: "80px" }
        }
      >
        <NavInner className="container flexSpaceCenter">
          <Link
            className="pointer flexNullCenter"
            to="home"
            duration={2000}
            smooth={true}
          >
            <div className="flexCenter">
            <LinkDOM to="/" style={{ textDecoration: "none" }}>
              <img src={paudclogo1} alt="logo" style={{ height: 40, margin: 10 }} />
            </LinkDOM>
            </div>
          </Link>

          <UlWrapper className="flexNullCenter">
            {navLinks.map((val, i) => {
              const { label, offset, children, to } = val;
              return (
                <li className="semiBold font14 pointer" key={i}>
                  {children ? (
                    <Dropdown label={label} list={children} />
                  ) : to ? (
                    <LinkDOM to={to} style={navStyle}>
                      {label}
                    </LinkDOM>
                  ) : (
                    <Link
                      className="animate"
                      activeClass="active-link"
                      style={navStyle}
                      to={label.toLowerCase()}
                      spy={true}
                      smooth={true}
                      offset={offset}
                      onClick={handleClick}
                    >
                      {label}
                    </Link>
                  )}
                </li>
              );
            })}
          </UlWrapper>

          <UlWrapperRight className="flexNullCenter">
            <Button
              className="lightLink"
              variant="contained"
              disableElevation
              href={contactLink}
            >
              Register Now
            </Button>
          </UlWrapperRight>

          <MenuWrapper className="borderBox radius6">
            <IconButton onClick={() => toggleSidebar(!sidebarOpen)}>
              <MenuRounded />
            </IconButton>
          </MenuWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.5);  /* Adjust the alpha value here */
  top: 0;
  left: 0;
  z-index: 999;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  display: none;
  @media (max-width: 760px) {
    display: flex;
  }
`;
