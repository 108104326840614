// screens
import iScreen1 from "./screens/screen2.jpg";
import iScreen2 from "./screens/screen3.jpg";
import iScreen3 from "./screens/screen4.jpg";
import iScreen4 from "./screens/screen5.jpg";
import iScreen5 from "./screens/screen6.jpg";
import iScreen6 from "./screens/screen1.jpg";
import iScreen7 from "./screens/screen7.jpg";
import iScreen8 from "./screens/screen8.jpg";
import iScreen9 from "./screens/screen9.jpg";
import iScreen10 from "./screens/screen10.jpg";

export const Screen1 = iScreen1;
export const Screen2 = iScreen2;
export const Screen3 = iScreen3;
export const Screen4 = iScreen4;
export const Screen5 = iScreen5;
export const Screen6 = iScreen6;
export const Screen7 = iScreen7;
export const Screen8 = iScreen8;
export const Screen9 = iScreen9;
export const Screen10 = iScreen10;