import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { tradeFinanceData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";
import { contactLink } from "../constants/links";

export const HeaderSection = () => (
  <Wrapper className="gradient1 combDark">
    <div className="container whiteColor">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Travel Advisory: <HighlightSpan>Ensuring Your Safe Journey</HighlightSpan>
            </h1>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
          Your safety and well-being are paramount to us. As you prepare for PAUDC 2024, 
          we want to provide some essential travel advice to ensure a smooth and secure journey to Uganda:
          </TitleP>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <HeaderButton to={contactLink}>Register Today</HeaderButton>
        </div>
      </LeftSide>
    </div>
  </Wrapper>
  
);

export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
          <b>Visa Requirements:</b> Before traveling, please check the visa requirements for Uganda based on your nationality. Ensure you have the necessary documents to obtain a visa or entry permit.
          <br />
          <br />
          <b>Health Precautions: </b> Stay informed about any health advisories or vaccination requirements for traveling to Uganda. Consider getting vaccinated against common diseases and carry essential medications.
          <br />
          <br />
          <b>Currency and Banking:</b> Familiarize yourself with the local currency (Ugandan Shilling) and banking facilities available. It's advisable to carry a mix of cash and cards for convenience.
          <br />
          <br />
          <b>Safety Tips:</b> While Uganda is generally safe for travelers, exercise caution and be aware of your surroundings, especially in crowded areas and at night. Keep your belongings secure and avoid displaying valuables openly.
          <br />
          <br />
          <b>Transportation Options:</b> Plan your transportation from the airport to your accommodation in advance. Consider using reputable taxi services or arrange for airport transfers with your hotel.
          <br />
          <br />
          <b>Emergency Contacts:</b> Save important contact numbers, including local emergency services, your embassy or consulate, and the event organizers. In case of any unforeseen circumstances, you'll have access to assistance.
          <br />
          <br />
          </TitleP>
        </div>
    </div>
  </ContentWrapper>
);

export const TravelAdvisory = () => {
  return (
    <>
      <HeaderSection />
      <ContentSection />
      <PatternStrip />
      <Footer />
    </>
  );
};

const Wrapper = styled.section`
  padding-top: 90px;
  padding-bottom: 50px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
  background: url('./banner2.jpg');
  background-size: cover; /* Ensure the background image covers the whole viewport */
  background-position: center; /* Center the background image */
  color: white; /* Set text color to white */
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
