// import React from "react";
// import Slider from "react-slick";
// import styled from "styled-components";
// // Components
// import { testimonialData } from "../../constants/siteData";
// import TestimonialBox from "../testimonial/TestimonialBox";

// export default function TestimonialSlider() {
//   const settings = {
//     speed: 1000,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     arrows: false,
//     centerMode: true,
//     autoplay: true,
//     infinite: true,
//     responsive: [
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };
//   return (
//     <Slider {...settings}>
//       {testimonialData.map((val, i) => {
//         return (
//           <LogoWrapper
//             data-aos="fade-right"
//             data-aos-offset="400"
//             data-aos-duration="1000"
//             data-aos-delay={i * 100}
//             className="flexCenter"
//             key={i}
//           >
//             <TestimonialBox data={val} />
//           </LogoWrapper>
//         );
//       })}
//     </Slider>
//   );
// }

// const LogoWrapper = styled.div`
//   width: 90%;
//   padding: 0 5% 7% 5%;
//   cursor: pointer;
//   :focus-visible {
//     outline: none;
//     border: 0px;
//   }
// `;


import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

// List of poster images
const posters = [
  "people1.jpg",
  "people2.jpg",
  "people3.jpg",
  "people4.jpg",
  "people5.jpg",
  "people6.jpg",
  "people7.jpg",
  "people8.jpg",
  "people9.jpg",
  "people10.jpg",
  "people11.jpg",
  "people12.jpg",
  "people13.jpg",
  "people14.jpg",
  "people15.jpg",
  "people16.jpg",
  "people17.jpg",
  "people18.jpg",
  "people19.jpg",
  "people20.jpg",
  "people21.jpg",
  "people22.jpg",
  "people23.jpg",
  "people24.jpg",
  "people25.jpg",
  "people26.jpg",
  "people27.jpg",
  "people28.jpg",
  "people29.jpg",
  "people30.jpg",
  "people31.jpg",
  "people32.jpg",
  "people33.jpg",
  "people34.jpg",
  "people35.jpg",
  "people36.jpg",
  "people37.jpg",
];

export default function TestimonialSlider() {
  const settings = {
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {posters.map((poster, i) => (
        <PosterWrapper
          key={i}
          data-aos="fade-right"
          data-aos-offset="400"
          data-aos-duration="1000"
          data-aos-delay={i * 100}
        >
          <PosterImage src={`/${poster}`} alt={`Testimonial ${i + 1}`} />
        </PosterWrapper>
      ))}
    </Slider>
  );
}

// Styled components
const PosterWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PosterImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    max-width: 90%;
  }
`;
