import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from "@mui/icons-material";

export const KyambogoLink = "/kyambogo";
export const ItenaryDetailsLink = "/itenary-details";
export const contactLink = "/contact";
export const TourPackagesLink = "/packages";
export const AccomodationLink = "/accomodation";
export const TravelAdvisoryLink = "/travel-advisory";
export const VisaApplicationLink = "/visa-application";
export const RegistrationLink = "/registration";
export const PaymentInfoLink = "/payment-info";
export const OrganizersLink = "/organizers";
export const SponsorsLink = "/sponsors";
export const VolunteersLink = "/volunteers";

export const navLinks = [
  {
    label: "Home",
  },
  {
    label: "About",
  },
  {
    label: "Why Uganda",
  },
  {
    label: "Partners & Sponsors",
  },
  {
    label: "Venue",
    children: [
      {
        label: "Kyambogo University",
        to: KyambogoLink,
      },
      {
        label: "Accomodation",
        to: AccomodationLink,
      },
      {
        label: "Travel Advisory",
        to: TravelAdvisoryLink,
      },
      {
        label: "Visa Application",
        to: VisaApplicationLink,
      },
    ],
  },
  {
    label: "Tournament",
    children: [
      {
        label: "Registration",
        to: RegistrationLink,
      },
      {
        label: "Packages",
        to: TourPackagesLink,
      },
      {
        label: "Payment Info",
        to: PaymentInfoLink,
      },
      {
        label: "Schedule/Itinerary",
        to: ItenaryDetailsLink,
      },
    ],
  },
  {
    label: "Team",
    children: [
      {
        label: "Organizing Committee",
        to: OrganizersLink,
      },
      {
        label: "Volunteers",
        to: VolunteersLink,
      },
      {
        label: "Partners & Sponsors",
        to: SponsorsLink,
      },
    ],
  },
];

export const socialLinks = [
  {
    Icon: FacebookRounded,
    link:
      "https://www.facebook.com/paudc2024",
  },
  {
    Icon: Instagram,
    link: "https://twitter.com/paudc2024",
  },
  {
    Icon: Twitter,
    link: "https://twitter.com/paudc2024",
  },
  {
    Icon: LinkedIn,
    link: "https://www.facebook.com/paudc2024",
  },
  {
    Icon: YouTube,
    link: "https://www.facebook.com/paudc2024",
  },
];

export const locationLink =
  "https://www.google.com";
