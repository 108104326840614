import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { Screen3Img } from "../assets/img/safaris/SafarisImages";
import { financialData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";

export const HeaderSection = () => (
  <div className="poka-pattern">
    <Wrapper className="container flexSpaceCenter">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Tournament <HighlightSpan>Schedule & Itinerary</HighlightSpan>
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
              <b>(7 Nights/ 8 Days)</b> in The Pearl of Africa
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
            Embark on a journey of intellectual exploration and cultural exchange with our meticulously 
            crafted tournament schedule and itinerary. From the moment of arrival to the final farewell, 
            participants will be immersed in a dynamic program designed to maximize learning, engagement, and enjoyment. 
            <br />
            <br />
            Each day offers a blend of competitive debate rounds, interactive workshops, and social events, creating an 
            unforgettable experience for all involved. Join us as we embark on this extraordinary adventure of debate, 
            camaraderie, and discovery in the heart of Uganda.
            <br />
          </TitleP>
        </div>

        {/* <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <HeaderButton link="https://google.com/">
            Visit Now
          </HeaderButton>
        </div> */}
      </LeftSide>
      <RightSide>
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <img src={Screen3Img} width="100%" alt="Mekkah" />
        </div>
      </RightSide>
    </Wrapper>
  </div>
);

export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">
      <GridWrapper>
        {financialData.map((val) => {
          const { title, Icon, content } = val;
          return (
            <CardWrapper className="radius8 borderBox">
              <h2 className="flexNullCenter">
                <Icon sx={{ mr: 3, fontSize: 30 }} color="primary" />
                {title}
              </h2>
              <Divider sx={{ my: 3 }} />
              <div className="font14"> {content}</div>
            </CardWrapper>
          );
        })}
      </GridWrapper>
    </div>
  </ContentWrapper>
);

export const ItenaryDetails = () => {
  return (
    <>
      <HeaderSection />
      <ContentSection />
      <PatternStrip />
      <Footer />
    </>
  );
};
const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  padding-left: 80px;
  @media (max-width: 960px) {
    width: 100%;
    padding: 30px;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
