import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { tradeFinanceData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";
import { contactLink } from "../constants/links";

export const HeaderSection = () => (
  <Wrapper className="gradient1 combDark">
    <div className="container whiteColor">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              <HighlightSpan>Visa</HighlightSpan> Application Guide for PAUDC 2024
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
              Traveling <b>Guidelines</b> To Our International Delegates
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
            Welcome to the Visa Application page for PAUDC 2024! We understand that securing 
            the necessary travel documentation is an essential step in ensuring a smooth and 
            hassle-free journey to our event. In this section, we provide comprehensive guidance 
            on the visa application process, including essential information, requirements, and 
            helpful tips to assist you in obtaining your visa. Whether you're a participant, observer, 
            or official, we're here to support you every step of the way in navigating the visa 
            application process for your attendance at the Pan African Universities Debating 
            Championship in Kampala, Uganda.
          </TitleP>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <HeaderButton to={contactLink}>Register Today</HeaderButton>
        </div>
      </LeftSide>
    </div>
  </Wrapper>
  
);

export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
            <b>All visitors traveling to the Republic of Uganda are advised to:</b>
            <br />
            <br />
            Hold a Passport with a validity of not less than 6 months at the time of application for the visas
            <br />
            <br />
            Apply for visas prior to travel for all those countries that require entry visas to the Republic of Uganda: <a href="https://visas.immigration.go.ug/">https://visas.immigration.go.ug/</a>
            <br />
            <br />
            Delegates from countries that do not have reciprocal visa exemption arrangements with the Republic of Uganda are advised to apply for visas online at <a href="https://visas.immigration.go.ug/">https://visas.immigration.go.ug/</a> and will receive an approval notification after which the visas will be endorsed in their passports at the point of entry.
            <br />
            <br />
            Have proof of Yellow Fever Vaccination at the point of entry into Uganda.
            <br />
            <br />
            Delegates from countries that do not have reciprocal visa exemption arrangements with the Republic of Uganda are advised to apply for visas online at <a href="http://www.visas.immigration.go.ug">http://www.visas.immigration.go.ug</a> and will receive an approval notification after which the visas will be endorsed in their passports at the point of entry.
            <br />
            <br />
            Delegates are also advised to visit <a href="https://www.immigration.go.ug/">https://www.immigration.go.ug/</a>, <a href="https://www.health.go.ug/">https://www.health.go.ug/</a>, and <a href="https://www.mofa.go.ug/">https://www.mofa.go.ug/</a> for additional travel information.
            <br />
            <br />
            <b>Visa Exempt Countries: Citizens from the countries listed below do not require visas to enter Uganda, and can proceed with accreditation.</b>
            <br />
            <br />
            <b>EAST AFRICA</b>
            <br />
            <br />
            Kenya , Burundi, Rwanda, United Republic of Tanzania
            <br />
            <br />
            <b>COMESA</b>
            <br />
            <br />
            Angola, Botswana, Comoros, Eritrea, Eswatini, Kenya, Madagascar, Malawi, Mauritus, Seychelles, Zambia, Zimbabwe
            <br />
            <br />
            <b>OTHER COUNTRIES</b>
            <br />
            <br />
            Antigua, Bahamas, Barbados, Belize, Cyprus, Fiji, Gambia, Ghana, Grenada, Ireland, Jamaica, Lesotho, Libya, Malta, Sierra Leone, Singapore, Solomon Islands, St. Vincent & Grenadines, Tonga, Vanuatu
            <br />
            <br />
          </TitleP>
        </div>
    </div>
  </ContentWrapper>
);


export const VisaApplication = () => {
  return (
    <>
      <HeaderSection />
      <ContentSection />
      <PatternStrip />
      <Footer />
    </>
  );
};

const Wrapper = styled.section`
  padding-top: 90px;
  padding-bottom: 50px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
  background: url('./tourist-visa.png');
  background-size: cover; /* Ensure the background image covers the whole viewport */
  background-position: center; /* Center the background image */
  color: white; /* Set text color to white */
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
