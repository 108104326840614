import React from "react";
import styled from "styled-components";
import { HeaderInfo, HeaderTitle, TitleP } from "../components/styled/Styled";
import { Chip, Divider, alpha } from "@mui/material";
import { plansData } from "../constants/siteData";
import { TaskAltRounded } from "@mui/icons-material";
import Footer from "../sections/Footer";
import { GenericButton } from "../components/buttons/GenericButton";
import { contactLink } from "../constants/links";

const getAttributes = (index) => {
  var className;
  switch (index) {
    case 1:
      className = "silverBg";
      break;
    case 2:
      className = "goldBg";
      break;

    default:
      break;
  }
  return { className: className };
};

const PlanBox = ({ data, index }) => {
  const { title, desc, button, tag, features } = data;
  return (
    <CardWrapper
      data-aos="fade-up"
      data-aos-delay={index * 100}
      data-aos-duration="1500"
      index={index}
      className="flexColumn flexSpaceNull gap10 radius8 animate font14"
    >
      <div className="flex flexColumn gap10">
        {/* Tag */}
        <div>
          <Chip
            className={getAttributes(index).className}
            label={tag}
            color={index === 0 ? "default" : "primary"}
          />
        </div>

        {/* Title */}
        <h1>{title}</h1>

        {/* Desc */}
        <p>{desc}</p>

        {/* List */}
        <Divider sx={{ my: 1 }} />
        {features.map((feature) => (
          <li className="flexNullCenter gap10" key={feature}>
            <TaskAltRounded color="primary" sx={{ fontSize: 17 }} /> {feature}
          </li>
        ))}
      </div>

      {/* button */}
      <Divider sx={{ my: 1 }} />

      <GenericButton
        className={getAttributes(index).className}
        to={contactLink}
      >
        {button}
      </GenericButton>
    </CardWrapper>
  );
};

export const TourPackages = () => {
  return (
    <>
      <Wrapper className="flexCenter content">
        <div className="container">
          <HeaderInfo className="whiteColor flex flexColumn gap5">
            <div>
              <Chip
                data-aos-duration="1000"
                data-aos="zoom-in-up"
                sx={{
                  backgroundColor: "white",
                }}
                label="Tournament Packages"
              />
            </div>
            <HeaderTitle>
              <p>Choose your Tournament Package</p>
            </HeaderTitle>
            <TitleP
              className="font14"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1500"
            > 
              Our tournament packages are crafted to give you the best experience
              possible. Choose from our packages below and get ready for a
              memorable experience.
            </TitleP>
          </HeaderInfo>

          {/* Grid */}
          <GridWrapper>
            {plansData.map((val, i) => (
              <PlanBox data={val} key={i} index={i} />
            ))}
          </GridWrapper>
        </div>
      </Wrapper>
      <Footer />
      <div className="background60 poka-pattern-dark" />
    </>
  );
};

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  margin-top: 70px;
  padding: 80px 0;
`;

const GridWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: 50px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 860px) {
    margin-top: 50px;
    gap: 30px;
    grid-template-columns: 1fr;
  }
`;
const CardWrapper = styled.div`
  padding: 40px;
  background-color: ${alpha("#fff", 0.95)};
  border: 1px solid ${alpha("#fff", 1)};
  box-shadow: rgba(0, 0, 0, 0.16) -40px 40px 80px;
`;
