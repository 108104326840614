import { createTheme } from "@mui/material";

// colors
const primaryColor = "#03393B"; //yellow
const goldColor = "#03393B"; //"#E5AD52";
const highlightColor = "#03393B"; //"#FFF100"; //gold 
const dimWhite = "#f2f2f2";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
  typography: {
    fontFamily: "Open Sans",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: 14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
});

export const antdTheme = {
  token: {
    fontFamily: "Open Sans",
    colorPrimary: primaryColor,
  },
};

export { theme, primaryColor, highlightColor, dimWhite };
