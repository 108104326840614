import { Chip, alpha } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { HeaderTitle, TitleP } from "../components/styled/Styled";
import { featuresData } from "../constants/siteData";
import { GenericButton } from "../components/buttons/GenericButton";
import { KyambogoLink } from "../constants/links";
import { getLocation } from "../utils/Utils";

export const Features = () => {
  return (
    <Wrapper className="gradient1 combDark" id="features">
      <div className="container whiteColor">
        <TitleWrapper className="flex flexColumn gap10">
          <div>
            <Chip
              sx={{
                fontSize: 12,
                mb: 1,
                backgroundColor: "#ffe7ca",
              }}
              label="PAUDC Kampala 2024"
            />
          </div>
          <HeaderTitle>
            About PAUDC 2024: <span className="lightGoldColor">Exploring Six Key Areas</span>
          </HeaderTitle>
          <TitleP className="font12 lightGoldColor">
            Exploring the Essence of PAUDC 2024
          </TitleP>
        </TitleWrapper>

        <GridWrapper>
          {featuresData.map((val, i) => (
            <CardItem data={val} key={i} index={i} />
          ))}
        </GridWrapper>

        {getLocation() === "/" && (
          <div className="textCenter">
            <GenericButton className="lightGoldBg" to={KyambogoLink}>
              Our Host Kyambogo University
            </GenericButton>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const CardItem = ({ data, index }) => {
  const { title, Icon, desc } = data;
  const width = 30;
  return (
    <CardWrapper index={index} className="flex gap20 radius8 animate pointer">
      <div>
        <Icon
          className="lightGoldColor"
          style={{
            height: width,
            width: width,
            marginTop: 10,
          }}
        />
      </div>

      <div>
        <p className="semiBold lightGoldColor">{title}</p>
        <p
          className="font13 light"
          style={{ lineHeight: 1.8, marginBottom: 20 }}
        >
          {desc}
        </p>
      </div>
    </CardWrapper>
  );
};

// const Wrapper = styled.section`
//   width: 100%; 
//   padding: 120px 0;
//   @media (max-width: 960px) {
//     padding: 70px 0;
//   }

//   background: url('./banner2.jpg')
// `;

const Wrapper = styled.section`
  padding: 120px 0;
  @media (max-width: 960px) {
    padding: 70px 0;
  }

  background: url('./banner14.jpg');
  background-size: cover;
  background-position: center;
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`;

const TitleWrapper = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const CardWrapper = styled.div`
  padding: 10px 20px;
  line-height: 3rem;
  backdrop-filter: blur(10px);
  background-color: ${alpha("#fff", 0.1)};
  border: 1px solid ${alpha("#fff", 0.2)};
  :hover {
    scale: 1.04;
    // box-shadow: rgba(0, 0, 0, 0.16) -40px 40px 80px;
  }
`;
