import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { MekkahImg } from "../assets/img/safaris/SafarisImages";
import { contactLink } from "../constants/links";

export const KyambogoHome = () => {
  return (
    <div className="poka-pattern">
      <Wrapper className="container flexSpaceCenter">
        <LeftSide className="flex flexColumn">
          <GapWrapper gap={10}>
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
            >
              <h1 className="extraBold font60">
                <HighlightSpan>Kyambogo University</HighlightSpan>
              </h1>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="600"
            >
              <div className="font18 extraBold">
                History of Kyambogo University
              </div>
            </div>
          </GapWrapper>

          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
            <TitleP className="font14">
              Kyambogo University (KYU) is a public university in Uganda. It is one of the eight public universities 
              and degree-awarding institutions in the country. The university was established in 2003 following the 
              merger of the former Uganda Polytechnic Kyambogo (UPK), the Institute of Teacher Education, Kyambogo (ITEK), 
              and the Uganda National Institute for Special Education (UNISE). The university campus is located on Kyambogo Hill 
              in the capital city, Kampala, approximately 8 kilometres (5 mi), east of the city centre along the Kampala-Jinja Highway.
          
              <br />
              <br />
              The Kyambogo University Debate Society prides itself on being a 
              well-organized and efficient intellectual family with over 10 years of 
              institutional experience, capable of managing and hosting regional-quality 
              tournaments. It is one of Uganda’s oldest and most successful debating 
              society. Kyambogo University has won the Uganda Debate Nationals more 
              than any other institution in Uganda, historically winning back-to-back in 
              2016 and 2017. 
              <br />
              <br />
              Regionally, Kyambogo University hosted the historically most successful East 
              African Universities Debate Championships in 2018, having registered the 
              highest number of teams and quality to-date with a Continental Rich CAP 
              headed by the famous Ejim David and Pius Mkpouto both from Nigeria with 
              mind blowing Excursions, four years down the road, with more hosting 
              experience, the Conveners of EAUDC 2018, Mr. Wabwiire Shabil and Mr. 
              Ssegawa Ivan Sebastian, now Engineers, are set to host the Continent at this 
              might institution. 
              <br />
              <br />
              However, the KYUDS is not in this alone. We are working closely with other 
              unions in the central and the rest of Uganda in order to share the skills, 
              knowledge and experiences that we have gained as a circuit
              <br />
              <br /> 

            </TitleP>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            <HeaderButton to={contactLink}>Register Today</HeaderButton>
          </div>
        </LeftSide>
        <RightSide>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            <img src={MekkahImg} width="100%" alt="Mekkah" />
          </div>
        </RightSide>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  padding: 30px;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
  }
`;
