import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { tradeFinanceData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";
import { contactLink } from "../constants/links";

export const HeaderSection = () => (
  <Wrapper className="gradient1 combDark">
    <div className="container whiteColor">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              <HighlightSpan>Payment</HighlightSpan> Information
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
              <b>Pay</b> Today!
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
          Secure your participation at the Pan African Universities Debating Championship (PAUDC) 
          2024 by following these payment guidelines:
          </TitleP>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <HeaderButton to={contactLink}>Register Today</HeaderButton>
        </div>
      </LeftSide>
    </div>
  </Wrapper>
  
);

export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
          <b>Accepted Payment Methods: </b> We accept payments via credit/debit cards, bank transfers, and other secure online payment options. Choose the method that is most convenient for you.
          <br />
          <br />
          <b>Installment Payments:  </b> To make the registration process more accessible, we offer installment payment plans for certain packages. This allows you to pay for your selected package in multiple installments leading up to the event.
          <br />
          <br />
          <b>Payment Deadlines:</b> Ensure that all payments are made by the specified deadlines to avoid any inconvenience. Late payments may result in the forfeiture of your registration, so make sure to plan accordingly.
          <br />
          <br />
          <b>Payment Confirmation: </b> Upon successful payment, you will receive a payment confirmation email containing your transaction details and a receipt. Keep this email for your records and reference.
          <br />
          <br />
          <b>Refund Policy: </b> Please review our refund policy carefully before making any payments. Refunds may be subject to certain conditions and deadlines, so familiarize yourself with the terms to avoid any misunderstandings.
          <br />
          <br />
          <b>Assistance: </b> If you encounter any issues or require assistance with the payment process, don't hesitate to reach out to our dedicated support team. We are here to help ensure a smooth and hassle-free payment experience for all participants.
          <br />
          <br />
          </TitleP>
        </div>
    </div>
  </ContentWrapper>
);

export const PaymentInfo = () => {
  return (
    <>
      <HeaderSection />
      <ContentSection />
      <PatternStrip />
      <Footer />
    </>
  );
};

const Wrapper = styled.section`
  padding-top: 90px;
  padding-bottom: 50px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
  background: url('./banner14.jpg');
  background-size: cover; /* Ensure the background image covers the whole viewport */
  background-position: center; /* Center the background image */
  color: white; /* Set text color to white */
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
