import React from "react";

import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import {
  contactLink,
  KyambogoLink,
  ItenaryDetailsLink,
  TourPackagesLink,
} from "../../constants/links";

// Creating our own theme
const botColor = "#197B22";
const theme = {
  background: "white",
  headerBgColor: botColor,
  headerFontSize: "20px",
  botBubbleColor: botColor,
  headerFontColor: "white",
  botFontColor: "white",
  userBubbleColor: "#F2f2f2",
  userFontColor: "black",
};

// Set some properties of the bot
const config = {
  // botAvatar: Favicon,
  floating: true,
};

const steps = [
  {
    id: "0",
    message: "Hey, Welcome to Siiru Tours and Travels!",
    trigger: "1",
  },
  {
    id: "1",
    message: "How can i help you?",
    trigger: "options",
  },
  {
    id: "options",
    options: [
      { value: 1, label: "Packages", trigger: "packages" },
      { value: "murchison", label: "Murchision Falls", trigger: "endpoint" },
      { value: "hajj", label: "Hajj and Umrah", trigger: "endpoint" },
      { value: "contact", label: "Send us a message", trigger: "endpoint" },
    ],
  },

  {
    id: "packages",
    options: [
      { value: "free_plan", label: "Free Plan", trigger: "endpoint" },
      { value: "silver_plan", label: "Silver Plan", trigger: "endpoint" },
      { value: "gold_plan", label: "Gold Plan", trigger: "endpoint" },
    ],
  },

  {
    id: "endpoint",
    message: "You will be redirected shortly!",
    end: true,
  },
];

function handleEnd({ steps, values }) {
  console.log(values);
  let value = values[0] || values[1];
  if (value.includes("packages")) {
    window.location.replace(TourPackagesLink);
  } else if (value.includes("hajj")) {
    window.location.replace(KyambogoLink);
  } else if (value.includes("murchison")) {
    window.location.replace(ItenaryDetailsLink);
  } else if (value.includes("contact")) {
    window.location.replace(contactLink);
  }
  // alert(`Chat handleEnd callback! Number: ${values[1]}`);
}

export const paudcBot = () => {
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        handleEnd={handleEnd}
        headerTitle={<p className="font15">Siiru Bot</p>}
        steps={steps}
        {...config}
      />
    </ThemeProvider>
  );
};
