import React from "react";
import { HighlightSpan } from "../components/styled/Styled";
import styled from "styled-components";
import { objectivesData } from "../constants/siteData";
import { Chip } from "@mui/material";

const CardItem = ({ data, index }) => {
  const { title, Icon, desc } = data;
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay={index === 1 ? 200 : 1000}
    >
      <CardWrapper
        index={index}
        className="flexCenter flexColumn textCenter radius8 animate"
      >
        <Icon style={{ height: 50, width: 50 }} />

        <h2 style={{ margin: "64px 0px 16px" }}>{title}</h2>
        <p className="font14" style={{ lineHeight: 1.8 }}>
          {desc}
        </p>
      </CardWrapper>
    </div>
  );
};

export const Objectives = () => {
  return (
    <Wrapper id="about">
      <div className="container">
        <div
          className="textCenter"
          style={{ paddingBottom: 50, lineHeight: 2 }}
        >
          <Chip
            data-aos-duration="1000"
            data-aos="zoom-in-up"
            color="primary"
            label={<p className="font12">PAUDC Kampala 2024</p>}
          />

          <div data-aos="fade-up" data-aos-duration="1500">
            <h2 className="font30">
            Why Attend<HighlightSpan> PAUDC KAMPALA 2024</HighlightSpan> 
            </h2>
          </div>
        </div>

        <GridWrapper>
          {objectivesData.map((val, i) => (
            <CardItem data={val} key={i} index={i} />
          ))}
        </GridWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0 120px 0;
  @media (max-width: 960px) {
    padding: 70px 0;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 80px 50px;
  line-height: 3rem;
  height: 100%;
  box-shadow: ${(prop) =>
    prop.index === 1 && "rgba(145, 158, 171, 0.16) -40px 40px 80px"};
  :hover {
    box-shadow: rgba(145, 158, 171, 0.16) -40px 40px 80px;
    scale: 1.02;
  }
  @media (max-width: 860px) {
    padding: 50px 40px;
    box-shadow: rgba(145, 158, 171, 0.16) -40px 40px 80px;
  }
`;
