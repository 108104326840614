import iScreen1 from "./screen1.jpg";
import iScreen2 from "./screen2.jpg";
import iScreen3 from "./screen3.jpg";
import iScreen4 from "./screen4.jpg";
import iScreen5 from "./screen5.jpg";
import iScreen6 from "./screen6.jpg";
import iMekkah from "./Mekkah.jpg";


export const Screen1Img = iScreen1;
export const Screen2Img = iScreen2;
export const Screen3Img = iScreen3;
export const Screen4Img = iScreen4;
export const Screen5Img = iScreen5;
export const Screen6Img = iScreen6;
export const MekkahImg = iMekkah;


