import React from "react";
import { Why } from "../sections/Why";
import { KyambogoHome } from "../sections/KyambogoHome";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";

export const Kyambogo = () => {
  return (
    <>
      <KyambogoHome />
      <Why />
      <PatternStrip />
      <Footer />
    </>
  );
};
