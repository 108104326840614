import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { tradeFinanceData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";
import { contactLink } from "../constants/links";

export const HeaderSection = () => (
  <Wrapper className="gradient1 combDark">
    <div className="container whiteColor">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Valued <HighlightSpan>Partners and Sponsors</HighlightSpan>
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
            Collaborating for <b>Impactful</b> Change
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
            Discover the organizations and companies that are partnering with PAUDC KAMPALA 2024 to empower African 
            youth and promote intellectual discourse. From telecommunications giants to local businesses, our sponsors 
            and partners play a crucial role in making this tournament a reality. Learn more about how you can join us in 
            driving positive change and fostering growth across the continent.
          </TitleP>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <HeaderButton to={contactLink}>Register Today</HeaderButton>
        </div>
      </LeftSide>
    </div>
  </Wrapper>
  
);

export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">
      <GridWrapper>
        {tradeFinanceData.map((val) => {
          const { title, Icon, content } = val;
          return (
            <CardWrapper className="radius8 borderBox">
              <h2 className="flexNullCenter">
                <Icon sx={{ mr: 3, fontSize: 30 }} color="primary" />
                {title}
              </h2>
              <Divider sx={{ my: 3 }} />
              <div className="font14"> {content}</div>
            </CardWrapper>
          );
        })}
      </GridWrapper>
    </div>
  </ContentWrapper>
);

export const Sponsors = () => {
  return (
    <>
      <HeaderSection />
      <ContentSection />
      <PatternStrip />
      <Footer />
    </>
  );
};

const Wrapper = styled.section`
  padding-top: 90px;
  padding-bottom: 50px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
  background: url('./banner14.jpg');
  background-size: cover; /* Ensure the background image covers the whole viewport */
  background-position: center; /* Center the background image */
  color: white; /* Set text color to white */
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
