import React from "react";
import { Landing } from "../pages/Landing";
import { TourPackages } from "../pages/TourPackages";
import { Kyambogo } from "../pages/Kyambogo";
import { Contact } from "../sections/Contact";
import { ItenaryDetails } from "../pages/ItenaryDetails";
import { Accomodation } from "../pages/Accomodation";
import { TravelAdvisory } from "../pages/TravelAdvisory";
import { VisaApplication } from "../pages/VisaApplication";
import { Registration } from "../pages/Registration";
import { PaymentInfo } from "../pages/PaymentInfo";
import { Organizers } from "../pages/Organizers";
import { Sponsors } from "../pages/Sponsors";
import { Volunteers } from "../pages/Volunteers";

import {
  contactLink,
  KyambogoLink,
  ItenaryDetailsLink,
  TourPackagesLink,
  AccomodationLink,
  TravelAdvisoryLink,
  VisaApplicationLink,
  RegistrationLink,
  PaymentInfoLink,
  OrganizersLink,
  SponsorsLink,
  VolunteersLink,

} from "./links";



export const routes = [
  {
    path: "/",
    component: <Landing />,
  },
  {
    path: TourPackagesLink,
    component: <TourPackages />,
  },
  {
    path: contactLink,
    component: <Contact />,
  },
  {
    path: KyambogoLink,
    component: <Kyambogo />,
  },
  {
    path: ItenaryDetailsLink,
    component: <ItenaryDetails />,
  },
  {
    path: AccomodationLink,
    component: <Accomodation />,
  },
  {
    path: TravelAdvisoryLink,
    component: <TravelAdvisory />,
  },
  {
    path: VisaApplicationLink,
    component: <VisaApplication />,
  },
  {
    path: RegistrationLink,
    component: <Registration />,
  },
  {
    path: PaymentInfoLink,
    component: <PaymentInfo />,
  },
  {
    path: OrganizersLink,
    component: <Organizers />,
  },
  {
    path: SponsorsLink,
    component: <Sponsors />,
  },
  {
    path: VolunteersLink,
    component: <Volunteers />,
  },
];
