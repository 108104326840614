import React from "react";
import styled from "styled-components";
// Assets
import { contactLink, navLinks } from "../../constants/links";
import { Close } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Menu } from "antd";
import { RecursiveMenu } from "../menu/RecursiveMenu";
import { Link } from "react-router-dom";
import paudclogo1 from "../../assets/svg/logo/paudclogo1.png";


export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const openSidebar = () => toggleSidebar(!sidebarOpen);

  return (
    <Wrapper className="animate darkGreenBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <div className="flexCenter">
            <img src={paudclogo1} alt="logo" style={{ height: 50 , margin: 10 }} />
          </div>
        </div>
        <IconButton
          onClick={() => toggleSidebar(!sidebarOpen)}
          sx={{ color: "white" }} // Ensure Close icon is white
        >
          <Close />
        </IconButton>
      </SidebarHeader>

      <Menu
        className="whiteColor semiBold sidebar_menu"
        mode="inline"
        theme={null} // Ensure no default theme colors override
        style={{ color: "white", backgroundColor: "transparent" }} // Set menu text color to white
        onClick={() => toggleSidebar(!sidebarOpen)}
      >
        {RecursiveMenu(navLinks, openSidebar)}
      </Menu>

      <UlStyle className="flexSpaceCenter">
        <Button
          className="lightLink"
          variant="contained"
          sx={{ color: "white", borderColor: "white" }} // Make sure button text is white
          disableElevation
          href={contactLink}
        >
          Register Now
        </Button>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  max-width: 500px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")};
  z-index: 9999;
  background-color: #004d40; // Ensure sidebar background matches your design
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
  button {
    color: white !important; // Ensure button text stays white
    border-color: white;
  }
`;


// import React from "react";
// import styled from "styled-components";
// // Assets
// import { contactLink, navLinks } from "../../constants/links";
// import { Close } from "@mui/icons-material";
// import { Button, IconButton } from "@mui/material";
// import { Menu } from "antd";
// import { RecursiveMenu } from "../menu/RecursiveMenu";
// import { Link } from "react-router-dom";
// import paudclogo1 from "../../assets/svg/logo/paudclogo1.png";


// export default function Sidebar({ sidebarOpen, toggleSidebar }) {
//   const openSidebar = () => toggleSidebar(!sidebarOpen);

//   return (
//     <Wrapper className="animate darkGreenBg" sidebarOpen={sidebarOpen}>
//       <SidebarHeader className="flexSpaceCenter">
//         <div className="flexNullCenter">
//           <div className="flexCenter">
//             <img src={paudclogo1} alt="logo" style={{ height: 50 , margin: 10  }} />
//           </div>
//         </div>
//         <IconButton
//           onClick={() => toggleSidebar(!sidebarOpen)}
//           sx={{ color: "black" }}
//         >
//           <Close />
//         </IconButton>
//       </SidebarHeader>

//       <Menu
//         className="blackColor semiBold sidebar_menu"
//         mode="inline"
//         theme={null}
//         onClick={() => toggleSidebar(!sidebarOpen)}
//       >
//         {RecursiveMenu(navLinks, openSidebar)}
//       </Menu>

//       <UlStyle className="flexSpaceCenter">

//         <Button
//           className="lightLink"
//           variant="contained"
//           color="inherit"
//           disableElevation
//           href={contactLink}
//         >
//           Register Now
//         </Button>
//       </UlStyle>
//     </Wrapper>
//   );
// }

// const Wrapper = styled.nav`
//   max-width: 500px;
//   width: 100%;
//   height: 100vh;
//   position: fixed;
//   top: 0;
//   padding: 0 30px;
//   right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")};
//   z-index: 9999;
// `;
// const SidebarHeader = styled.div`
//   padding: 20px 0;
// `;

// const UlStyle = styled.ul`
//   padding: 40px;
//   li {
//     margin: 20px 0;
//   }
// `;
