import React from "react";
import { Hero } from "../sections/Hero";
import { Header } from "../sections/Header";
import Footer from "../sections/Footer";
import { Destinations } from "../sections/Destinations";
import { Features } from "../sections/Features";
import { Objectives } from "../sections/Objectives";
import { Testimonials } from "../sections/Testimonials";
import { PatternStrip } from "../sections/PatternStrip";
import { Faq } from "../sections/Faq";

export const Landing = () => {
  return (
    <>
      <Hero />
      <Header />
      <Objectives />
      <Features />
      <Destinations />
      <Testimonials />
      <Faq />
      <PatternStrip />
      <Footer />
    </>
  );
};
