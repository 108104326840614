// import React from "react";
// import { Container, makeStyles } from "@material-ui/core";
// import Carousel from "./Carousel";
// import { HighlightSpan } from "../components/styled/Styled";
// import { HeaderButton } from "../components/buttons/HeaderButton";
// import { contactLink } from "../constants/links";

// const useStyles = makeStyles((theme) => ({
//   banner: {
//     backgroundImage: "url(./banner14.jpg)",
//     position: "relative", // Add position relative
//     zIndex: 1, // Add a higher z-index value
//     backgroundSize: "cover", // Ensure the background covers the entire container
//     backgroundPosition: "center",
//     height: "100vh", // Set the initial height to cover the entire viewport
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   bannerContent: {
//     height: 400,
//     display: "flex",
//     flexDirection: "column",
//     paddingTop: 25,
//     justifyContent: "space-around",
//   },
//   tagline: {
//     display: "flex",
//     height: "40%",
//     flexDirection: "column",
//     justifyContent: "center",
//     textAlign: "center",
//   },
//   carousel: {
//     height: "50%",
//     display: "flex",
//     alignItems: "center",
//   },
// }));


// export const Hero = () => {
//   const classes = useStyles();

//   return (
//     <div className={classes.banner}>
//       <Container className={classes.bannerContent}>
//         <div className={classes.tagline}>
//           <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
//             <h1 className="extraBold font60" style={{ color: "white", marginBottom: "20px" , marginTop: "130px"}}>
//               Pan African Universities' Debate Championship (PAUDC) Kampala 2024
//             </h1>
//           </div>
//           <h2 className="font30" style={{ color: "white", marginTop: "20px" }}>
//             15th - 22nd December 2024
//           </h2>
//           <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" style={{ display: 'flex', justifyContent: 'center' , marginTop: "30px"}}>
//             <HeaderButton to={contactLink}>Register Today</HeaderButton>
//           </div>
//         </div>
        
//         <Carousel />
//       </Container>
//     </div>
//   );
// };


// import React from "react";
// import { Container, makeStyles } from "@material-ui/core";
// import Carousel from "./Carousel";
// import { HighlightSpan } from "../components/styled/Styled";
// import { HeaderButton } from "../components/buttons/HeaderButton";
// import { contactLink } from "../constants/links";

// const useStyles = makeStyles((theme) => ({
//   banner: {
//     backgroundColor: "#03393B", // Default background color while image loads
//     backgroundImage: "url(./banner14.jpg)",
//     position: "relative",
//     zIndex: 1,
//     backgroundSize: "cover", // Ensures the image covers the container
//     backgroundPosition: "center", // Default positioning for desktop
//     height: "100vh",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     transition: "background-position 20s ease-in-out", // Smooth transition
//     [theme.breakpoints.down("sm")]: {
//       backgroundPosition: "left", // Start image from the left on mobile
//       animation: "$slideImage 30s linear infinite", // Slowed down horizontal sliding animation for mobile
//     },
//   },
//   "@keyframes slideImage": {
//     "0%": {
//       backgroundPosition: "left", // Start from left
//     },
//     "100%": {
//       backgroundPosition: "right", // Slide to the right
//     },
//   },
//   bannerContent: {
//     height: 400,
//     display: "flex",
//     flexDirection: "column",
//     paddingTop: 25,
//     justifyContent: "space-around",
//   },
//   tagline: {
//     display: "flex",
//     height: "40%",
//     flexDirection: "column",
//     justifyContent: "center",
//     textAlign: "center",
//   },
//   carousel: {
//     height: "50%",
//     display: "flex",
//     alignItems: "center",
//   },
// }));

// export const Hero = () => {
//   const classes = useStyles();

//   return (
//     <div className={classes.banner}>
//       <Container className={classes.bannerContent}>
//         <div className={classes.tagline}>
//           <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
//             <h1 className="extraBold font60" style={{ color: "white", marginBottom: "20px" , marginTop: "130px"}}>
//               Pan African Universities' Debate Championship (PAUDC) Kampala 2024
//             </h1>
//           </div>
//           <h2 className="font30" style={{ color: "white", marginTop: "20px" }}>
//             15th - 22nd December 2024
//           </h2>
//           <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" style={{ display: 'flex', justifyContent: 'center' , marginTop: "30px"}}>
//             <HeaderButton to={contactLink}>Register Today</HeaderButton>
//           </div>
//         </div>
        
//         <Carousel />
//       </Container>
//     </div>
//   );
// };


import React from "react";
import { Container, makeStyles } from "@material-ui/core";
import Carousel from "./Carousel";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { contactLink } from "../constants/links";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: "#03393B", 
    backgroundImage: "url(./banner14.jpg)",
    position: "relative",
    zIndex: 1,
    backgroundSize: "cover", 
    backgroundPosition: "center", 
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-position 20s ease-in-out", 
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "left", 
      animation: "$slideImage 30s linear infinite", 
    },
  },
  "@keyframes slideImage": {
    "0%": {
      backgroundPosition: "left", 
    },
    "100%": {
      backgroundPosition: "right", 
    },
  },
  bannerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 25,
    height: "100%", // Use full height for better alignment
    textAlign: "center",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0, // Remove extra padding on mobile
    },
  },
  tagline: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(3),
    maxWidth: "90%", // Responsive width for mobile
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1), // Reduce padding on mobile for space
    },
  },
  h1: {
    fontSize: "2.5rem", // Adjusted for better balance
    fontWeight: 700, 
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem", // Responsive size for mobile
    },
  },
  separator: {
    height: "4px",
    width: "50px",
    background: "linear-gradient(to right, #ffcc00, #ff6600)", // More creative separator
    marginBottom: theme.spacing(2),
  },
  h2: {
    fontSize: "1.3rem",
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
  theme: {
    fontSize: "1.1rem", 
    fontWeight: 300,
    fontStyle: "italic",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2), // Ensure button is visible on mobile
    },
  },
  carousel: {
    height: "50%",
    display: "flex",
    alignItems: "center",
  },
}));

export const Hero = () => {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <Container className={classes.bannerContent}>
        <div className={classes.tagline}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className={classes.h1}>
              Pan African Universities' Debate Championship (PAUDC) Kampala 2024
            </h1>
            <div className={classes.separator} /> {/* Elegant separator */}
          </div>
          <h2 className={classes.h2}>15th - 22nd December 2024</h2>
          <p className={classes.theme}>
            Science, Technology, and Innovation as a Tool for Peace Building in Africa
          </p>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className={classes.buttonContainer}>
            <HeaderButton to={contactLink}>Register Today</HeaderButton>
          </div>
        </div>
        <Carousel />
      </Container>
    </div>
  );
};
